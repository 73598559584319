import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Transactions from './app/views/transactions/index';
import Invoice from './app/views/invoice/index';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/t/:id" component={Transactions} exact />
          <Route path="/b/:id" component={Invoice} exact />
          <Route component={() => {
          global.window && (global.window.location.href = 'https://udhaarkhaata.com');
          return null;
          }} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
