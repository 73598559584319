import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Redirect } from 'react-router';

export default class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        customer: "",
        khaata: "",
        number: "",
        dates: "",
        debit: 0.0,
        credit: 0.0,
        balance: 0.0,
        transactions: [],
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
  
    fetch(`https://production.apis.udhaarkhaata.com/api/transaction/customer/${params.id}`)
    .then(response => response.json())
    .then(data => this.setState({ 
        khaata: data.khaata.name,
        debit: data.total_give,
        credit: data.total_get,
        balance: data.total_give - data.total_get,
        customer: data.customer_name,
        transactions: data.transactions,
        number: data.khaata.mobile,
        dates: data.transactions[0].created_at + " - " + data.transactions[data.transactions.length-1].created_at
     }))
     .catch(error => {
         console.log("Error");
         window.location.href = "https://udhaarkhaata.com";
    });;
  }

  renderTransactions() {
    let transactions = [];
    this.state.transactions.reverse().map((transaction, i) => {
        transactions.push(
            <tr>
                <td>{transaction.created_at}</td>
                <td class="error">{transaction.type === "GIVE" ? "₹ " + transaction.amount : ""}</td>
                <td class="success">{transaction.type === "GET" ?  "₹ " + transaction.amount : ""}</td>
                <td>₹ {transaction.balance}</td>
            </tr>
        )
    })
    return transactions;
  }

  render() {
    return (
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-8 col-md-6 mx-auto">
                            <div class="card">
                                <div class="card-header">
                                    <div class="card-title row">
                                        <div class="col-md-8 col xs-8">
                                        <h4 class="text-left owner-name">{this.state.khaata}</h4>
                                        <h6 class="text-left owner-contact">
                                            <svg class="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"></path></svg> <a href={"tel:"+this.state.number}  style={{textDecoration: "None", color: "#FFFFFF"}}>Call</a>
                                            <svg class="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path></svg> <a href={"https://api.whatsapp.com/send?phone=" + this.state.number} style={{textDecoration: "None", color: "#FFFFFF"}}>WhatsApp</a>
                                        </h6>
                                        </div>
                                        <div class="col-md-4 col xs-4">
                                            <div className="uk-logo">
                                                <img height="20" width="150" src="/images/uk-full-white.png"></img>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr/> */}
                                    
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="text-center transaction-title">Transaction History</h6>
                                            <h6 class="text-center transaction-caption">({this.state.dates})</h6>
                                        </div>
                                        <div class="language-container">
                                            {/* <h6 class="text-center language-controller">English</h6> */}
                                        </div>
                                    </div>
                                    <div class="row border transaction-summary">
                                        <div class="col-sm-4 col xs-8">
                                            <div class="transaction-summary-header">Total Debit(-)</div><span class="transaction-summary-content">₹ {this.state.debit}</span>
                                        </div>
                                        <div class="col-sm-4 col xs-8 border-right border-left">
                                            <div class="transaction-summary-header">Total Credit(+)</div><span class="transaction-summary-content">₹ {this.state.credit}</span>
                                        </div>
                                        <div class="col-sm-4 col xs-8">
                                            <div class="transaction-summary-header">Net Balance</div><span class="transaction-summary-content">₹ {Math.abs(this.state.balance)} {this.state.balance > 0 ? "Dr": "Cr"}<br/>({this.state.customer} will {this.state.balance > 0 ? "Give": "Get"})</span>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <table class="table border table-curved">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Debit(-)</th>
                                                    <th>Credit(+)</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {this.renderTransactions()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
  }
}