import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import QRCode from 'qrcode.react';
import { Redirect } from 'react-router';

export default class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: "",
        date: "",
        customer: "",
        khaata: "",
        number: "",
        paid: 0.0,
        total: 0.0,
        products: [],
        pagelink: ""
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({
        pagelink: "https://share.udhaarkhaata.com/b/" + params.id
    })
  
    fetch(`https://production.apis.udhaarkhaata.com/api/bill/invoice/${params.id}`)
    .then(response => response.json())
    .then(data => this.setState({ 
        id: data.invoice.id,
        date: data.invoice.created_at,
        khaata: data.khaata.name,
        paid: data.invoice.details.paid,
        total: data.invoice.details.total,
        customer: data.customer_name,
        products: data.invoice.details.products,
        number: data.khaata.mobile,
     }))
     .catch(error => {
         console.log(error);
         window.location.href = "https://udhaarkhaata.com";
    });;
  }

  renderInvoice() {
    let products = [<li className="cart_item">
    <span className="name"><b>Name</b></span>
    <span className="index"><b>Qn</b></span>
    <span className="index"><b>Unit</b></span>
    <span className="price">Amt</span>
</li>];
this.state.products.reverse().map((product, i) => {
    products.push(
        <li className="cart_item">
            <span className="name">{product.name}</span>
            <span className="index">{product.quantity}</span>
            <span className="index">{product.unit}</span>
            <span className="price">₹ {product.mrp}</span>
        </li>
    )
})

    return products;
  }

  render() {
    return (
    <div className="container-invoice">
        <p className="store-header">{this.state.khaata}</p>
        <p className="invoice-header">INVOICE</p>
        <div className="receipt_box">
            <div className="head">
                <div className="logo">
                    <svg width="55" height="55" viewBox="0 0 514 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M147 498.947C147 506.139 141.194 512 134.034 512H12.966C5.80606 512 0 506.139 0 498.947V13.0527C0 5.83534 5.80606 0 12.966 0H134.034C141.194 0 147 5.83534 147 13.0527V498.947Z" fill="#61313E"/>
                        <path d="M147 498.947C147 506.139 141.194 512 134.034 512H12.966C5.80606 512 0 506.139 0 498.947V13.0527C0 5.83534 5.80606 0 12.966 0H134.034C141.194 0 147 5.83534 147 13.0527V498.947Z" fill="#63A343"/>
                        <path d="M256 499.914C256 506.597 251.713 512 246.386 512H156.614C151.312 512 147 506.597 147 499.914V51.0599C147 44.4026 151.312 39 156.614 39H246.386C251.713 39 256 44.4026 256 51.0599V499.914Z" fill="#22B1B6"/>
                        <path d="M367 501.38C367 507.254 362.635 512 357.212 512H265.814C260.39 512 256 507.254 256 501.38V106.646C256 100.771 260.39 96 265.814 96H357.212C362.635 96 367 100.771 367 106.646V501.38Z" fill="#EA5645"/>
                        <path d="M514 498.947C514 506.139 508.194 512 501.034 512H379.966C372.806 512 367 506.139 367 498.947V13.0527C367 5.83534 372.806 0 379.966 0H501.034C508.194 0 514 5.83534 514 13.0527V498.947Z" fill="#FFC23A"/>
                        <path d="M380.123 0C372.876 0 367 5.83505 367 13.0777V498.922C367 506.139 372.876 512 380.123 512H407V0H380.123Z" fill="#F9A431"/>
                        <path d="M514 466H367V446H514V466Z" fill="#4F823C"/>
                        <path d="M514 434H367V417H514V434Z" fill="#4F823C"/>
                        <path d="M514 404H367V387H514V404Z" fill="#4F823C"/>
                        <path d="M473 83.5129C473 101.461 458.461 116 440.513 116C422.565 116 408 101.461 408 83.5129C408 65.565 422.565 51 440.513 51C458.461 51 473 65.565 473 83.5129Z" fill="#22B1B6"/>
                        <path d="M262.894 96C259.091 96 256 100.771 256 106.646V501.38C256 507.254 259.091 512 262.894 512H277V96H262.894Z" fill="#D13431"/>
                        <path d="M367 331H256V130H367V331Z" fill="#63A343"/>
                        <path d="M277 331H256V130H277V331Z" fill="#4F823C"/>
                        <path d="M158.148 38C151.98 38 147 43.4312 147 50.0921V499.908C147 506.594 151.98 512 158.148 512H181V38H158.148Z" fill="#179998"/>
                        <path d="M256 246H147V145H256V246Z" fill="#FFC23A"/>
                        <path d="M256 367H147V353H256V367Z" fill="#FFC23A"/>
                        <path d="M181 246H147V145H181V246Z" fill="#F9A431"/>
                        <path d="M181 367H147V353H181V367Z" fill="#F9A431"/>
                        <path d="M13.1225 0C5.87616 0 0 5.84499 0 13.0743V498.926C0 506.155 5.87616 512 13.1225 512H40V0H13.1225Z" fill="#4F823C"/>
                        <path d="M147 179H0V39H147V179Z" fill="#EA5645"/>
                        <path d="M147 427H0V409H147V427Z" fill="#EA5645"/>
                        <path d="M147 457H0V437H147V457Z" fill="#EA5645"/>
                        <path d="M40 179H0V39H40V179Z" fill="#D13431"/>
                        <path d="M40 425H0V409H40V425Z" fill="#D13431"/>
                        <path d="M40 457H0V437H40V457Z" fill="#D13431"/>
                        <path d="M147 145H0V83H147V145Z" fill="#FFC23A"/>
                        <path d="M40 145H0V83H40V145Z" fill="#F9A431"/>
                    </svg>
                </div>
                <div className="number">
                    {/* <div className="info"><b>{this.state.khaata}</b></div> */}
                    <div className="date"><b>Date:</b> {this.state.date}</div>
                    <div className="ref"><b>Order:</b> {this.state.id}</div>
                    <div className="ref"><b>Mobile:</b> {this.state.number}</div>
                </div>
            </div>
            <div className="body-invoice">
                <div className="info">
                    <div className="welcome">Hi, <span className="username">{this.state.customer}</span></div>
                    <p>You've purchased ({this.state.products.length}) items.</p>
                </div>
                <div className="cart">
                    {/* <div className="title">cart</div> */}
                    <div className="content">
                        <ul className="cart_list">
                            {this.renderInvoice()}
                        </ul>
                        <div className="total">
                            <span>total</span>
    <span className="total_price">₹ {this.state.total}</span>
                        </div>
                        <div className="total">
                            <span>paid</span>
                            <span className="total_paid">₹ {this.state.paid}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="foot">
                {/* <img src="https://i.ibb.co/c8CQvBq/barcode.png" alt="barcode" className="barcode"></img> */}
                <QRCode 
                    value={this.state.pagelink}
                    size={200}
                    imageSettings={{
                        src: "/logo.png",
                        x: null,
                        y: null,
                        height: 24,
                        width: 24,
                        excavate: true,
                      }}/>
            </div>
            <p className="uk-footer">This bill was generated using <b>UdhaarKhaata</b> digital billing system.</p>

        </div>
    </div>
    );
  }
}